export default {
  en: {
    label: 'English',
    phone: 'Phone',
    email: 'Email',
    contactUs: 'Contact us',
    ourLocation: 'Our location',
    readMore: 'Read more',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
    share: 'Share',
    shareCopy: 'Copy',
    shareCopied: 'Copied!',
  },
  de: {
    label: 'Deutsch',
    phone: 'Telefonnummer',
    email: 'E-Mail-Adresse',
    contactUs: 'Kontakt',
    ourLocation: 'Unsere Standorte',
    readMore: 'Weiterlesen',
    formError: 'Sadly, something went wrong...',
    formSuccess: 'Your message has been sent.',
    share: 'Diese Seite teilen',
    shareCopy: 'kopieren',
    shareCopied: 'Kopiert!',
  },
};
