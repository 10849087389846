module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"createPages":{"redirectPagesWithoutThisLang":"/en/"},"wordpress":[{"protocol":"https","domain":"backend.lignostar.com","language":"en","normalizers":[{"name":"acf"}],"includedRoutes":["**/vacancies"]},{"protocol":"https","domain":"backend.lignostar.com","language":"de","normalizers":[{"name":"acf"}],"includedRoutes":["**/vacancies"]}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LignoStar.com","short_name":"LignoStar","start_url":"/?pwa=true","background_color":"#003366","theme_color":"#003366","display":"minimal-ui","icon":"src/images/favicon.png","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"34da9ac2cd3d04b7c1c85bbfe35bf784"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-TM3E5BX9BC","UA-36988470-1"],"pluginConfig":{"head":false,"respectDNT":true,"anonymize_ip":true,"exclude":[]}},
    }]
