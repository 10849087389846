// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-gatsby-theme-wordpress-templates-page-js": () => import("./../../../src/gatsby-theme-wordpress/templates/page.js" /* webpackChunkName: "component---src-gatsby-theme-wordpress-templates-page-js" */),
  "component---src-gatsby-theme-wordpress-templates-template-contact-php-js": () => import("./../../../src/gatsby-theme-wordpress/templates/template-contact.php.js" /* webpackChunkName: "component---src-gatsby-theme-wordpress-templates-template-contact-php-js" */),
  "component---src-gatsby-theme-wordpress-templates-template-homepage-php-js": () => import("./../../../src/gatsby-theme-wordpress/templates/template-homepage.php.js" /* webpackChunkName: "component---src-gatsby-theme-wordpress-templates-template-homepage-php-js" */),
  "component---src-gatsby-theme-wordpress-templates-template-your-industry-php-js": () => import("./../../../src/gatsby-theme-wordpress/templates/template-your-industry.php.js" /* webpackChunkName: "component---src-gatsby-theme-wordpress-templates-template-your-industry-php-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-vacancy-js": () => import("./../../../src/templates/vacancy.js" /* webpackChunkName: "component---src-templates-vacancy-js" */)
}

